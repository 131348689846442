<template>
  <list-template
    :search-config="searchConfig"
    @onSearch="onSearch"
    :table-config="tableConfig"
    :table-data="tableData"
    :total="total"
    :loading="loading"
    :current-page="page"
    @onChangePage="handleCurrentChange"
    @onHandle="handleClick"
    @onChange="onChange"></list-template>
</template>

<script>
import { mapState } from 'vuex'
export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  name: 'List',
  computed: {
    ...mapState(['page'])
  },
  created() {
    this.$store.commit('setPage', 1)
    this.getData()
    this.getSchool()
    this.getGrade()
  },
  methods: {
    getData() {
      let params = {
        page: this.page,
        ...this.search
      }
      this.loading = true
      this.$_axios2
        .get('/api/teacher/exercise/record', { params })
        .then(res => {
          const {
            data: {
              data: { list, page }
            }
          } = res
          this.total = page.total
          this.pageCount = page.last_page
          this.tableData = list
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    commonInterface() {
      this.$_axios2('api/evaluation/result/get-range').then(res => {
        const {
          data: { status, data }
        } = res
        if (status === 0) {
          this.searchConfig[2].options = data.school
          this.searchConfig[3].options = data.grade
        }
      })
    },
    getSchool() {
      this.$_axios.get('/site/school').then(res => {
        let { data } = res.data
        if (data.length > 0) {
          data.unshift({
            id: '0',
            isSelected: false,
            school_name: '全部'
          })
          this.searchConfig[2].options = data
        }
      })
    },
    getGrade() {
      this.$_axios.get('/site/grades').then(res => {
        let { data } = res.data
        if (data.length > 0) {
          data.unshift({
            id: '0',
            isSelected: false,
            name: '全部'
          })
          this.searchConfig[3].options = data
        } else {
          this.searchConfig[3].options = []
        }
      })
    },
    onSearch(val) {
      this.$store.commit('setPage', 1)
      const { date, ...data } = val
      this.search = data
      if (date) {
        this.search.statistics_date = date[0]
        this.search.statistics_end_date = date[1]
      }
      this.getData()
    },
    onChange(val) {
      this.search = val
    },
    handleClick(row) {
      this.$router.push('./details?id=' + row.id)
    },
    handleCurrentChange(val) {
      this.$store.commit('setPage', val)
      this.getData()
    }
  },
  data() {
    return {
      search: null,
      tableData: [],
      loading: false,
      //总条数
      total: 0,
      pageCount: 0,
      searchConfig: [
        {
          tag: 'input',
          prop: 'teacher_name',
          placeholder: '搜索教师姓名'
        },
        {
          tag: 'datePicker',
          placeholder: '筛选提交日期',
          prop: 'date',
          property: {
            'value-format': 'yyyy-MM-dd',
            type: 'daterange',
            'range-separator': '至',
            'start-placeholder': '开始日期',
            'end-placeholder': '结束日期',
            clearable: false
          }
        },
        {
          tag: 'select',
          placeholder: '筛选校区',
          prop: 'school_id',
          options: [],
          label: 'school_name',
          value: 'id',
          change: (val, search) => {
            this.search = search
            if (search.grade_id) this.search.grade_id = ''
          }
        },
        {
          tag: 'select',
          placeholder: '筛选年级',
          prop: 'grade_id',
          options: [],
          label: 'name',
          value: 'id'
        }
      ],
      tableConfig: [
        {
          prop: 'teacher_name',
          label: '班主任姓名'
        },
        {
          prop: 'class_name',
          label: '行政班'
        },
        {
          prop: 'grade_name',
          label: '年级'
        },
        {
          prop: 'school_name',
          label: '校区'
        },
        {
          prop: 'updated_at',
          label: '提交日期'
        },
        {
          prop: 'handle',
          label: '操作',
          width: '120rem',
          handle: true,
          buttons: [{ type:"view", text:"查看" }]
        }
      ]
    }
  }
}
</script>

<style scoped></style>
